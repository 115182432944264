import Link from 'next/link'
import React from 'react'
import { useRouter } from 'next/router'

import { getLabelFromCategory, handlePhoneOut } from '@/utils'
import { Segment } from '../../../../types'
import TTY from '../../atoms/TTY'

export function Disclaimer({
  category,
  phoneNum,
}: {
  category: Segment.Category
  phoneNum: string
}) {
  const router = useRouter()
  const formattedCategory = getLabelFromCategory(category).split(' ')[0]
  const displayCategory =
    formattedCategory === 'Auto'
      ? formattedCategory.toLowerCase()
      : formattedCategory

  const isStep2 = router.query.step === '2'

  return (
    <div className="mx-auto mt-10 -mb-1 w-full text-left text-sm">
      <label>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        <span className="mb-2">
          Consent:{' '}
          {category === 'health-insurance' && (
            <>
              This is a solicitation for insurance. Products and services
              include Medicare Supplement, Medicare Advantage, and Prescription
              Drug Plans. Any information we provide is limited to those plans
              we do offer in your area. Please contact{' '}
              <Link
                href="http://medicare.gov/"
                className="text-blue-primary underline"
                rel="noopener"
                target="_blank"
              >
                Medicare.gov
              </Link>{' '}
              or 1–800–MEDICARE to get information on all of your options
              <br />
            </>
          )}
          By clicking &quot;See My GreatQuotes&quot;
          {isStep2 && ' or "Next Step" '}
          above and <em><strong>by ticking the check box for SMS opt-in</strong></em>, I provide my electronic signature and express written consent
          to telemarketing calls, text messages, emails, and/or postal mail from
          RateQuote at the email address, postal address, or phone number that I provided above
          (including wireless numbers, if applicable, even if previously registered on any Federal, State, or
          company Do Not Call (DNC) Registry). I consent to receiving text messages only from the RateQuote call center regarding insurance quotes,
          insurance information, customer care, marketing, and updates. 
          Text message frequency varies and may include up to 2 messages per week.
          Message and data rates may apply based on carrier.
          For assistance, text HELP to 1-813-395-9389 to receive support or contact 
          RateQuote at 1-804-902-6630 or email us at compliance@ratequote.com.
          To opt-out of receiving SMS messages, text STOP to unsubscribe, and RateQuote 
          will no longer send you text messages or submit Do Not Call request here.
          By clicking on &quot;See My GreatQuotes&quot; above, and submitting this form I agree that I am 18+ years of age and I confirm that I have
          read and agree to the{' '}
          <Link
            href="/terms-conditions/"
            target="_blank"
            rel="noopener"
            className="text-blue-primary underline"
          >
            Terms
          </Link>{' '}
          and{' '}
          <Link
            href="/privacy-policy/"
            target="_blank"
            rel="noopener"
            className="text-blue-primary underline"
          >
            Privacy Policy
          </Link>
        </span>
        <br />
        <span>
          {`If you wish to receive ${displayCategory} insurance quotes without providing the
            above consent please call`}{' '}
          <a
            className="text-blue-primary underline"
            href={`tel:+${phoneNum}`}
            onClick={() => handlePhoneOut({ category, phoneNumber: phoneNum })}
          >
            {phoneNum}
            <TTY category={category} />
          </a>
          .
        </span>
      </label>
    </div>
  )  
}
